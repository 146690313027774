import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "../../../axios-config";
import { closeModal } from "../../../Redux/actions/ui";
import { Button, TextField, Typography, Box, Grid, IconButton, Dialog, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const RegisterContainer = ({ closeModal }) => {
  const [state, setState] = useState({
    contactName: "",
    companyName: "",
    email: "",
    phone: "",
    description: "",
    activePage: "INTEREST",
    errorMessage: "",
  });

  const handleClose = () => {
    setState({ ...state, activePage: "INTEREST" });
    closeModal();
  };

  const submitInterest = () => {
    axios
      .post("https://api.inventum3D.com/requestinterest", {
        contactName: state.contactName,
        email: state.email,
      })
      .then(() => {
        setState({ ...state, errorMessage: "", activePage: "SUBMIT_INTEREST" });
      })
      .catch(console.error);
  };

  const submitProject = () => {
    axios
      .post("https://api.inventum3D.com/requestproject", {
        contactName: state.contactName,
        companyName: state.companyName,
        email: state.email,
        phone: state.phone,
        description: state.description,
      })
      .then(() => {
        setState({ ...state, errorMessage: "", activePage: "SUBMIT_PROJECT" });
      })
      .catch(console.error);
  };

  const updateValue = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const setError = (message) => {
    setState({ ...state, errorMessage: message });
  };

  return (
    <Dialog open fullWidth maxWidth="md" onClose={handleClose}>
      <DialogContent>
        <HeroElement />
        {state.activePage === "INTEREST" && (
          <RegisterInterest
            handleClose={handleClose}
            setError={setError}
            error={state.errorMessage}
            updateValue={updateValue}
            email={state.email}
            contactName={state.contactName}
            submitInterest={submitInterest}
            handleAddProject={() => setState({ ...state, activePage: "REQUEST_PROJECT" })}
          />
        )}
        {state.activePage === "REQUEST_PROJECT" && (
          <RequestProject
            handleBack={() => setState({ ...state, activePage: "INTEREST" })}
            handleClose={handleClose}
            setError={setError}
            error={state.errorMessage}
            updateValue={updateValue}
            email={state.email}
            contactName={state.contactName}
            companyName={state.companyName}
            phone={state.phone}
            description={state.description}
            submitProject={submitProject}
          />
        )}
        {["SUBMIT_INTEREST", "SUBMIT_PROJECT"].includes(state.activePage) && (
          <AfterSubmit handleClose={handleClose} activePage={state.activePage} />
        )}
      </DialogContent>
    </Dialog>
  );
};

function HeroElement() {
  return (
    <Box
      sx={{
        position: "relative",
        backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.734) 50%, rgba(0, 0, 0, 0.896) 100%), url(/static/img/hero.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: "2rem",
        color: "#fff",
        textAlign: "center",
        borderRadius: "8px",
        margin: "1rem",
        marginBottom: "2rem",
      }}>
      <Typography variant="h4" component="div" sx={{ fontWeight: "bold", mb: 2 }}>
        Unlock Your 3D Data
      </Typography>
      <Typography variant="body1" component="p" sx={{ lineHeight: 1.6 }}>
        Use Inventum3D to view your 3D models in the browser.
        <br />
        No plugins or downloads required.
        <br />
        Stand out from the crowd with a 3D viewer in your website or presentation.
      </Typography>
    </Box>
  );
}

function RegisterInterest({ handleClose, setError, error, updateValue, email, contactName, submitInterest, handleAddProject }) {
  const handleSubmit = () => {
    if (!/^\S+@\S+\.\S+$/.test(email)) {
      setError("Valid Email Address Required");
      return;
    }
    submitInterest();
  };

  return (
    <Box>
      <IconButton onClick={handleClose} sx={{ position: "absolute", top: 4, right: 4}}>
        <CloseIcon />
      </IconButton>
      <Typography variant="h5" gutterBottom>
        Register Interest
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        We are still developing our self-service system. Submit your email, and we’ll keep you updated.
      </Typography>
      <TextField
        label="Name (Optional)"
        fullWidth
        variant="outlined"
        sx={{ mb: 2 }}
        value={contactName}
        onChange={(e) => updateValue("contactName", e.target.value)}
      />
      <TextField
        label="Email"
        fullWidth
        variant="outlined"
        sx={{ mb: 2 }}
        value={email}
        onChange={(e) => updateValue("email", e.target.value)}
      />
      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}
      <Button variant="contained" fullWidth onClick={handleSubmit} sx={{ mb: 2 }}>
        Submit
      </Button>
      <Typography align="center" sx={{ mb: 2 }}>
        OR
      </Typography>
      <Button variant="outlined" fullWidth onClick={handleAddProject}>
        Add My Project!
      </Button>
    </Box>
  );
}

function RequestProject({
  handleBack,
  handleClose,
  setError,
  error,
  updateValue,
  email,
  contactName,
  companyName,
  phone,
  description,
  submitProject,
}) {
  const handleSubmit = () => {
    if (!/^\S+@\S+\.\S+$/.test(email)) {
      setError("Valid Email Address Required");
      return;
    }
    if (!contactName) {
      setError("Contact Name Required");
      return;
    }
    submitProject();
  };

  return (
    <Box>
      <IconButton onClick={handleClose} sx={{ position: "absolute", top: 4, right: 4 }}>
        <CloseIcon />
      </IconButton>
      <Button onClick={handleBack} sx={{ mb: 2 }}>
        Back
      </Button>
      <Typography variant="h5" gutterBottom>
        Add My Project
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Fill out this form, and we’ll be in touch to discuss your project.
      </Typography>
      <TextField
        label="Contact Name"
        fullWidth
        variant="outlined"
        sx={{ mb: 2 }}
        value={contactName}
        onChange={(e) => updateValue("contactName", e.target.value)}
      />
      <TextField
        label="Email"
        fullWidth
        variant="outlined"
        sx={{ mb: 2 }}
        value={email}
        onChange={(e) => updateValue("email", e.target.value)}
      />
      <TextField
        label="Company Name (Optional)"
        fullWidth
        variant="outlined"
        sx={{ mb: 2 }}
        value={companyName}
        onChange={(e) => updateValue("companyName", e.target.value)}
      />
      <TextField
        label="Phone (Optional)"
        fullWidth
        variant="outlined"
        sx={{ mb: 2 }}
        value={phone}
        onChange={(e) => updateValue("phone", e.target.value)}
      />
      <TextField
        label="Project Details"
        multiline
        rows={4}
        fullWidth
        variant="outlined"
        sx={{ mb: 2 }}
        value={description}
        onChange={(e) => updateValue("description", e.target.value)}
      />
      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}
      <Button variant="contained" fullWidth onClick={handleSubmit}>
        Submit
      </Button>
    </Box>
  );
}

function AfterSubmit({ handleClose, activePage }) {
  return (
    <Box textAlign="center">
      <IconButton onClick={handleClose} sx={{ position: "absolute", top: 4, right: 4 }}>
        <CloseIcon />
      </IconButton>
      <Typography variant="h5" gutterBottom>
        Thank You For Your Interest
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        {activePage === "SUBMIT_PROJECT" ? "We’ll contact you soon to discuss your project." : "We’ll keep you updated with Inventum3D."}
      </Typography>
      <Button variant="contained" onClick={handleClose}>
        Close
      </Button>
    </Box>
  );
}

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => {
    dispatch(closeModal());
  },
});

export default connect(null, mapDispatchToProps)(RegisterContainer);
