import React, { useState } from 'react';
import { EditTextPill } from '../HeaderCommon.js';
import ClippingValueInput from './ClippingValueInput.js'

function ClippingCollection (props) {
  const style = {
    'marginLeft': '5px'
  };

  const createPlane = () => {
    Inventum.clipping.createPlane();
    props.getClippingPlanes();
  }

  return (
    <div style={style}>
      <div style={{padding: '5px'}}>
        <span className='CreateLightsButton' onClick={createPlane}><i className="material-icons notranslate">add</i>Plane</span>
      </div>
      {props.clippingPlanes.map((plane, index) => <PlaneEntry models={props.models} getClippingPlanes={props.getClippingPlanes} plane={plane} key={index} />)}
    </div>
  )
}

function PlaneHeader (props) {
  const deletePlane = (e) => {
    e.stopPropagation();
    Inventum.clipping.deletePlane(props.plane.id);
    props.getClippingPlanes();
  };

  const duplicatePlane = (e) => {
    e.stopPropagation();
    Inventum.clipping.clonePlane(props.plane.id);
    props.getClippingPlanes();
  };

  const toggleActive = (e) => {
    e.stopPropagation();
    if (props.plane.active) {
      Inventum.clipping.deactivate(props.plane.id);
    }else {
      Inventum.clipping.activate(props.plane.id);
			Inventum.clipping.showHelper(props.plane.id);
    }
    props.getClippingPlanes();
  };

  const changeName = (text) => {
    Inventum.clipping.setName(props.plane.id, text);
    props.getClippingPlanes();
  };

	const toggleHelper = (e) => {
		e.stopPropagation();
		Inventum.clipping.toggleHelper(props.plane.id);
		props.getClippingPlanes();
	};
  return (
    <div className='LabelHeader' onClick={props.toggleOpen}>
      <span className='PanelOpenStatus'>{props.isOpen ? '▾' : '▸'}</span>
      <span className='LabelHeaderBtn delete' title='Delete Clipping Plane' onClick={deletePlane}><i className="material-icons notranslate">clear</i></span>
      <span className='LabelHeaderBtn' title='Clone Clipping Plane' onClick={duplicatePlane}><i className="material-icons notranslate">copy_all</i></span>
      <span className={'LabelHeaderBtn' + (props.plane.active ? ' active' : '')} title='Activate Plane' onClick={toggleActive}><i className="material-icons notranslate">{props.plane.active ? 'check_box' : 'check_box_outline_blank'}</i></span>
			<span className={'LabelHeaderBtn' + (props.plane.helperVisible ? ' active' : '')} title='Toggle Helper' onClick={toggleHelper}><i className="material-icons notranslate">{props.plane.helperVisible ? 'texture' : 'texture'}</i></span>
			<EditTextPill setText={(text) => {changeName(text)}} value={props.plane.name} label={props.plane.name} />
    </div>
  )
}

function PlaneDrawer (props) {
  const startPos = props.plane.startPosition;
  const endPos = props.plane.endPosition;
  const style = {
    'marginLeft': '5px',
    'background': '#383838',
    'padding': '5px',
    'borderRadius': '5px',
    'boxSizing': 'border-box'
  };
  return (
    <div style={style}>
      <PositionEditor
        phi={startPos.phi}
        theta={startPos.theta}
        distance={startPos.distance}
        title="Start Position"
        mode="START"
        planeID={props.plane.id}
      />
      <PositionEditor
        phi={endPos.phi}
        theta={endPos.theta}
        distance={endPos.distance}
        title="End Position*"
        hoverText="Requires duration greater than 0 to use"
        mode="END"
        planeID={props.plane.id}
      />{" "}
      <DurationInput
        name="Duration"
        value={props.plane.duration}
        min={0}
        max={60} // Add a maximum value for clarity
        step={1} // Step size for increment/decrement
        handleUpdate={(value) => {
          Inventum.clipping.setDuration(props.plane.id, Number(value) * 1000);
        }}
      />
      <ModelSelector getClippingPlanes={props.getClippingPlanes} plane={props.plane} models={props.models} />
    </div>
  );
}

function PositionEditor(props) {
  const { phi, theta, distance, title, hoverText, planeID, mode } = props;

  const updateInventum = (key, value) => {
    const data = { phi, theta, distance, [key]: value };
    Inventum.clipping.editPosition(planeID, mode, data);
  };

  return (
    <div style={{ marginBottom: "10px" }}>
      <span title={hoverText}>{title}</span>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <ClippingValueInput
          title="Pitch"
          tooltip="Pitch in degrees"
          value={phi}
          min={-360}
          max={360}
          handleChange={(value) => updateInventum("phi", value)}
        />
        <ClippingValueInput
          title="Yaw"
          tooltip="Yaw in degrees"
          value={theta}
          min={-360}
          max={360}
          handleChange={(value) => updateInventum("theta", value)}
        />
        <ClippingValueInput
          title="Distance"
          tooltip="Distance in units"
          value={distance}
          min={0}
          max={1000} // Set an appropriate maximum distance
          handleChange={(value) => updateInventum("distance", value)}
        />
      </div>
    </div>
  );
}


function ModelSelector (props) {
  const handleClick = (modelID) => {
    if (props.plane.models.includes(modelID)) {
      Inventum.clipping.removeModel(props.plane.id, modelID);
    }else {
      Inventum.clipping.addModel(props.plane.id, modelID);
    }
    props.getClippingPlanes();
  };

  return (
    <div style={{marginTop: '10px', height: '200px', position: 'relative', display: 'flex', flexDirection: 'column'}}>
      <div>
        <span>Models</span>
        <span className='ModelsCopyPasteBtn' onClick={() => {Inventum.clipping.copyModels(props.plane.id)}}>Copy</span>
        <span className='ModelsCopyPasteBtn' onClick={() => {Inventum.clipping.pasteModels(props.plane.id)}}>Paste</span>
      </div>
      <div style={{height: '100%', overflowY: 'scroll', backgroundColor: '#222', borderRadius: '5px', padding: '5px', marginTop: '5px'}}>
        {props.models.map((modelGroup, groupIndex) => {
          return (
            <div key={groupIndex} style={{marginBottom: '5px'}}>
              <div>{modelGroup.name}</div>
              <div style={{display:'flex', flexDirection:'column', alignItems: 'flex-start', marginTop: '5px'}}>
                {modelGroup.models.map((model, modelIndex) => {
                  return (
                    <span className={'ModelNamePill' + (props.plane.models.includes(model.id) ? ' active' : '')} key={modelIndex} onClick={() => { handleClick(model.id) }}>{ model.name }</span>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

function PlaneEntry (props) {
  const [isOpen, setOpen] = useState(false);
  return (
    <div>
      <PlaneHeader plane={props.plane} isOpen={isOpen} getClippingPlanes={props.getClippingPlanes} toggleOpen={() => { props.getClippingPlanes(); setOpen(!isOpen); Inventum.clipping.removeHelper(props.plane.id); }} />
      {isOpen ? <PlaneDrawer models={props.models} plane={props.plane} getClippingPlanes={props.getClippingPlanes} /> : null}
    </div>
  )
}

class InventumClippingEditor extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      open: true,
      clippingPlanes: [],
      models: []
    };
    this.getClippingPlanes = this.getClippingPlanes.bind(this);
  }

  componentDidMount () {
    this.getClippingPlanes();
  }

  componentWillUnmount () {
    this.state.clippingPlanes.map(({id}) => {
      Inventum.clipping.removeHelper(id);
    });
  }

  getClippingPlanes () {
    const clippingPlanes = Inventum.clipping.getClippingPlanes();
    const models = Inventum.models.getModelsSerialized();
    this.setState({clippingPlanes: clippingPlanes, models: models});
  }

  render () {
    let style = {
      background:'#FFF',
      pointerEvents:'all'
    }

    const toggleOpen = () => {this.setState({ open: !this.state.open })};

		const handleHelp = (e) => {
			e.stopPropagation();
			window.open('https://docs.inventum3d.com/clipping/', '_blank');
		};


    return (
      <div className='LabelsPanel'>
				<div onClick={toggleOpen} className="EditorPanelHeader">
          <span className='PanelOpenStatus'>{this.state.open ? '▾' : '▸'}</span>
          <span>Clipping Planes</span>
					<span title='Opens the Inventum Docs' className="EditorPanelHelpButton" onClick={handleHelp}><i className="material-icons notranslate">help</i></span>
        </div>
        {this.state.open ? <ClippingCollection models={this.state.models} getClippingPlanes={this.getClippingPlanes} clippingPlanes={this.state.clippingPlanes} /> : null}
      </div>
    )
  }
}

function DurationInput(props) {
  const { value, min, max, step, handleUpdate, name } = props;

  return (
    <div>
      Duration
      <div
        style={{
          display: "flex",
          fontSize: "14px",
          marginBottom: "10px",
          alignItems: "center",
          marginTop: "5px",
          gap: "10px",
        }}>
        <ClippingValueInput
          title="Seconds"
          tooltip="Duration in seconds"
          value={value}
          min={min}
          max={max}
          step={step}
          handleChange={(newValue) => handleUpdate(newValue)}
        />
      </div>
    </div>
  );
}



export default InventumClippingEditor
