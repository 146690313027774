import React from 'react';
import StaticValueInput from './StaticValueInput';

class StaticPanel extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render () {
    return (
      <div>
        <div className='EditorDrawerItemHeader' onClick={() => { this.setState({ open: !this.state.open }) }}>
          <span className='PanelOpenStatus'>{this.state.open ? '▾' : '▸'}</span>
          <span>Position & Scale</span>
        </div>
        {this.state.open ? <StyleDrawer
           {...this.props}
           positionX={this.props.positionX}
           positionY={this.props.positionY}
           scale={this.props.scale}
           />
        : null}
      </div>
    )
  }
}

function StyleDrawer(props) {
  let scaleDisplayRange = { min: 1, max: 20 };
  let scaleNormRange = { min: 0.1, max: 1 };

  return (
    <div>
      <StaticValueInput
        title="Scale"
        tooltip="1 - 20. Bigger may clip."
        min={scaleDisplayRange.min}
        max={scaleDisplayRange.max}
        value={Math.round(remapRange(props.scale, scaleNormRange.min, scaleNormRange.max, scaleDisplayRange.min, scaleDisplayRange.max))}
        handleChange={(value) => {
          Inventum.labels.setStaticScale(
            props.id,
            remapRange(value, scaleDisplayRange.min, scaleDisplayRange.max, scaleNormRange.min, scaleNormRange.max)
          );
          props.getLabels();
        }}
      />
      <StaticValueInput
        title="X Position"
        tooltip="0 - 100%. Bigger or smaller may clip."
        min={0}
        max={100}
        value={Math.round(props.positionX * 100)}
        handleChange={(value) => {
          Inventum.labels.setStaticPosition(props.id, { x: value / 100 });
          props.getLabels();
        }}
      />
      <StaticValueInput
        title="Y Position"
        tooltip="0 - 100%. Bigger or smaller may clip."
        min={0}
        max={100}
        value={Math.round(props.positionY * 100)}
        handleChange={(value) => {
          Inventum.labels.setStaticPosition(props.id, { y: value / 100 });
          props.getLabels();
        }}
      />
      <div>
        <span
          className="CenterLabelPositionButton"
          onClick={() => {
            Inventum.labels.centerStaticLabel(props.id);
            props.getLabels();
          }}>
          Center Position
        </span>
      </div>
    </div>
  );
}

function remapRange (value, fmin, fmax, tmin, tmax) {
    return tmin + (tmax - tmin) * (value - fmin) / (fmax - fmin);
}

export { StaticPanel }
