import React from 'react';
import Draggable from 'react-draggable';
import {connect} from 'react-redux';
import {closeModal} from '../../../Redux/actions/ui.js';
import Inventum from '../../../../engine/Inventum';

let CloseButton = (props) => {
  return (
    <div onClick={props.handleClick} className="titlebarCloseButton"><i className="material-icons notranslate">close</i></div>
  )
}

let MinimizeButton = (props) => {
  return (
    <div className="titlebarMinMaxButton" onClick={props.toggleMinimize}>
      <i className="material-icons notranslate">{props.minimized ? "crop_square" : "remove"}</i>
    </div>
  )
}

function WindowControls(props) {
  return (
    <div className="windowControlsContainer">
      <MinimizeButton minimized={props.minimized} toggleMinimize={props.toggleMinimize} />
      <CloseButton handleClick={props.handleClose} />
    </div>
  )
}

let Content2D = (props) => {
  return (
    <div className='helpModal'>
      <h3 style={{ marginTop: "0px" }}>Inventum 3D</h3>
      <p>Inventum3D is a powerful way to visualise 3D data in your web browser.</p>
      <p>
        For more information go to{" "}
        <a href="https://www.Inventum3d.com/about" target="_blank">
          Inventum3d.com/about
        </a>
      </p>
      <h3>Usage:</h3>
      <p>Click on a map marker to view the project in 3D.</p>
      <p>Black markers are currently using a legacy version of Inventum3D.</p>
      <p>You can also add map layers using the panel in the bottom of sidebar.</p>
      <p>Once you've added a layer you can drag the handle to change it's display order.</p>
    </div>
  );
}

let Content3D = (props) => {
  return (
    <div className="helpModal">
      <h3 style={{ marginTop: "0px" }}>Inventum 3D</h3>
      <p>Inventum3D is a powerful way to visualise 3D data in your web browser.</p>
      <p>
        For more information go to{" "}
        <a href="https://www.Inventum3d.com/about" target="_blank">
          Inventum3d.com/about
        </a>
      </p>
      <h3>Controls</h3>
      <p>Mouse Left click + Drag to orbit.</p>
      <p>Mouse Right click + Drag to pan.</p>
      <p>Mouse Scroll Wheel to zoom in and out.</p>
      <p>Transform Gizmo Controls (W = Transform, R = Rotate, E = Scale).</p>
      <p>You can toggle the visibility of objects in the sidebar menu.</p>
      <p>Alt G = Hide the Grid Floor. Pressing it again will hide the sky and alpha the background.</p>
      <p>Alt H = Hide the UI. Suitable for externally recording a video.</p>
    </div>
  );
}

let HelpContent = (props) => {
  return (
    <div className="HelpContent" style={{padding: '5px', color: '#FFF', fontFamily: 'Sans-Serif'}}>
      {props.viewerMode === "2D" ? <Content2D /> : <Content3D /> }
    </div>
  )
}

class HelpInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {title:"Help"}
    this.setTitle = this.setTitle.bind(this);
    this.toggleMinimize = this.toggleMinimize.bind(this);
  }

  toggleMinimize(force) {
    if (typeof(force) === "boolean") {
      this.setState({minimized:force});
    } else {
      this.setState({minimized:!this.state.minimized});
    }
  }

  setTitle(newTitle) {
    this.setState({title:newTitle})
  }

  render () {
    return (
      <Draggable handle=".popupDragHandle">
      <div className={"WorldEditorContainer" + (this.state.minimized ? " minimized" : "")}>
        <div className="popupDragHandle"><div style={{paddingLeft:"5px"}}>{this.state.title}</div><WindowControls minimized={this.state.minimized} toggleMinimize={this.toggleMinimize} handleClose={this.props.closeModal} /></div>
          {this.state.minimized ? null : <HelpContent viewerMode={this.props.viewerMode}/>}
        </div>
      </Draggable>
    )
  }
}

let mapDispatchToProps = dispatch => ({
  closeModal:() => {
    dispatch(closeModal());
  }
})


let mapStateToProps = (state) => ({
  viewerMode: state.sidebarUI.mode,
})

const HelpInfoContainer = connect(mapStateToProps,mapDispatchToProps)(HelpContent)

export default HelpInfoContainer
