import React, { useEffect } from "react";
import LoginContainer from './AccountLogin';
import {connect} from 'react-redux';
import {openModal, closeModal} from '../../Redux/actions/ui.js';
import {removeAllFilters} from '../../Redux/actions/projectsFilters.js';


function Button(props) {
  return (
    <div>
      <div className={"map-horizontal-button" + (props.disabled ? " disabled" : "") } onClick={props.onClick} title={props.title}>
        <span><i className="material-icons notranslate">{props.icon}</i>{props.name}</span>
      </div> 
    </div>
  )
}

function DropDownChoice(props) {
  return (
    <div className="edit-dropdown-entry" onClick={(e)=>{props.onClick()}}>
      <i className="material-icons notranslate">{props.icon}</i>{props.name}
    </div>
  )
}

const AboutInventum3D = (props) => {
  const handleClick = (e) => {
    window.open("https://inventum3d.com/about", "_blank");
  }
  return (
    <Button
      icon={"info"}
      name={"About"}
      onClick={handleClick}
    />
  );
}

const AddProject = (props) => {
  const isModalTriggered = React.useRef(false);

  useEffect(() => {
  // Check session storage for the flag set by the static website
  const params = new URLSearchParams(window.location.search);
  const fromStaticButton = params.get('fromStaticButton');

  if (fromStaticButton === "true" && !isModalTriggered.current) {
    props.openModal("RegisterInterest");
    isModalTriggered.current = true;
    const newURL = window.location.origin + window.location.pathname;
    window.history.replaceState({}, document.title, newURL);
  }}, [props])

  //Admins
  if (props.siteAdmin) {
    return null;
  }
  //Non-Admins
  return (
    <div onClick={()=>{props.openModal('RegisterInterest')}} className="AddProjectButton">
      <span><i className="material-icons notranslate">add_location</i>Add Your Project</span>
    </div>
  )
}

class EditInventumDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {open:false};
  }

  render() {
    let DropDownContainer = (props) => {
      return (
        <div className="editDropdownContainer">
          <DropDownChoice icon={"comment"} name={"Labels"} onClick={()=>{props.openModal("InventumLabelEditor")}} />
          <DropDownChoice icon={"tungsten"} name={"Lights"} onClick={()=>{props.openModal("InventumLightsEditor")}} />
          <DropDownChoice icon={"compare"} name={"Clipping"} onClick={()=>{props.openModal("InventumClippingEditor")}} />
          <DropDownChoice icon={"public"} name={"World"} onClick={()=>{props.openModal("InventumWorldEditor")}} />
          <DropDownChoice icon={"picture_in_picture"} name={"Slides (Legacy)"} onClick={()=>{props.openModal("InventumSlideEditor")}} />
        </div>
      )
    }

    return (
      <div>
        <div className={"map-horizontal-button"} onClick={()=>{this.setState({open:!this.state.open})}} title={"Edit"}>
          <span><i className="material-icons notranslate">edit</i>Edit</span>
          {this.state.open ? <DropDownContainer openModal={this.props.openModal} /> : null}
        </div>
      </div>
    )
  }
}

//      <Button icon={"settings"} name={"Settings"} onClick={()=>{props.openModal("Settings")}} disabled={true} />
const ViewerButtons = (props) => {
  let showProjectEditor = false;
  let showProjectSearch = false;
  let showTenementDirectory = false;
  let showAnalytics = false;

  if (props.pageMode === "3D" && props.siteAdmin && props.editMode) {
    showProjectEditor = true;
  }

  if (props.pageMode === "2D" && props.activePageType === "COMPANY") {
    if (props.siteAdmin) {
      showAnalytics = true;
    }else {
      if (props.userCompanies) {
        props.userCompanies.map(company => {
          if (company.id == props.activeCompanyID) {
            showAnalytics = true;
          }
        })
      }
    }
  }

  if (props.pageMode === "2D" && props.activePageType == "HOME") {
    showProjectSearch = true;
  }

  if (props.pageMode === "2D" && props.siteAdmin) {
    showTenementDirectory = true;
  }

  return (
    <div className={"map-horizontal-controls" + (props.uiHideMode === 'ALL' || props.uiHideMode === 'ANIMATION' ? ' hidden' : '')}>
      {showProjectEditor ? <EditInventumDropdown openModal={props.openModal} /> : null }
      {showProjectSearch ? <SearchButton siteAdmin={props.siteAdmin} openModal={props.openModal} /> : null}
      {<AboutInventum3D />}
      {showTenementDirectory ? <Button icon={"account_balance"} name={"Tenements"} onClick={()=>{props.openModal("TenementDirectory")}} /> : null}
      {showAnalytics ? <Button icon={"bar_chart"} name={"Analytics"} onClick={()=>{props.openModal("AnalyticsDirectory")}} /> : null}
      {props.pageMode === "3D" ? <Button icon={"settings"} name={"Settings"} onClick={()=>{props.openModal("SettingsEditor")}} /> : null}
      <Button icon={"help"} name={"Help"} onClick={()=>{props.openModal("HelpInfo")}} />
      <LoginContainer />
      <ClearFilter pageMode={props.pageMode} clearFilters={props.removeAllFilters} activeFilters={props.activeFilters} />
      <div className="map-horizontal-controls-bottom">
        {props.pageMode === "2D" ? <AddProject openModal={props.openModal} siteAdmin={props.siteAdmin}/> : null}
      </div>
    </div>
  )
}

const ClearFilter = (props) => {
  if (Object.keys(props.activeFilters).length == 0 || props.pageMode == "3D") {
    return null;
  }
  return (
    <div onClick={props.clearFilters} title="Clear Project Filters" className="clear-filter-button">
      Clear Project Filters
    </div>
  )
}

let mapDispatchToProps = dispatch => ({
  openModal:(modalName) => {
    dispatch(openModal(modalName));
  },
  removeAllFilters:() => {
    dispatch(removeAllFilters())
  }
})

let mapStateToProps = (state) => ({
  siteAdmin:(state.user.siteAdmin) ? true : false,
  activeModal:state.ui.activeModal,
  pageMode:state.sidebarUI.mode,
  editMode:state.ui.editMode,
  hasVisitedHome:state.ui.hasVisitedHome,
  uiHideMode: state.ui.hideMode,
  activePageType:state.activePage.type,
  activeCompanyID:state.activePage.companyID,
  activeProjectID:state.activePage.projectID,
  userCompanies:state.user.companies,
  activeFilters:state.projectsFilters.active
})

class SearchButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleShortcut = this.handleShortcut.bind(this);
  }

  componentDidMount () {
    window.addEventListener('keydown', this.handleShortcut);
  }

  handleShortcut (e) {
    if (e.ctrlKey && e.key === 'f') {
      this.props.openModal('ProjectDirectory');
      e.preventDefault();
    }
  }

  render() {
    return (
      <Button icon={"search"} name={this.props.siteAdmin ? "Search/Admin" : "Search"} onClick={()=>{this.props.openModal("ProjectDirectory")}} />
    )
  }

}

const ViewerButtonsContainer = connect(mapStateToProps, mapDispatchToProps)(ViewerButtons)


export default ViewerButtonsContainer;
