import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, ScaleControl, GeoJSON, Tooltip } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { clearNextBounds } from "../../Redux/actions/mapData.js";
import RouterForwarder from "./RouterForwarder.js";
import hash from "object-hash";

const mapCenter = [0, 0];
const zoomLevel = 3;
const worldBounds = [
  [-75, -300.0],
  [85, 300],
];
const australiaCenter = [-25.2744, 133.7751];
const australiaZoom = 5;


function CreateMarkerSVG(color) {
  return `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" view-box="0 0 24 24">
	<path fill="${color}" d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/>
</svg>
`;
}

function CreateLockSVG(color) {
  return `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fillRule="evenodd" clipRule="evenodd">
	<path fill="${color}" d="M18 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v14h18v-14h-3zm-5 7.723v2.277h-2v-2.277c-.595-.347-1-.984-1-1.723 0-1.104.896-2 2-2s2 .896 2 2c0 .738-.404 1.376-1 1.723zm-5-7.723v-4c0-2.206 1.794-4 4-4 2.205 0 4 1.794 4 4v4h-8z"/>
</svg>`;
}

let MarkersCluster = (props) => {
  if (!props.cluster) {
    return props.markers;
  }
  return (
    <MarkerClusterGroup showCoverageOnHover={true} disableClusteringAtZoom={7} maxClusterRadius={40}>
      {props.markers}
    </MarkerClusterGroup>
  );
};

class LeafletContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: [],
      layers: [],
      selectedTenement: null,
      offlineCoastline: null,
      mapLoaded: false,
      mapDelay: true,
    };
    this.map = React.createRef();
    this.getTenementInfo = this.getTenementInfo.bind(this);
    this.handleMovement = this.handleMovement.bind(this);
    this.fitViewBounds = this.fitViewBounds.bind(this);
    this.onMapCreated = this.onMapCreated.bind(this);
  }

  componentDidMount() {
    axios
      .get("/static/data/50m_land.geojson")
      .then((response) => {
        this.setState({ offlineCoastline: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  fitViewBounds(bounds) {
    if (this.map.current) {
      this.map.current.flyToBounds(bounds);
    }
  }

  componentDidUpdate() {
    if (this.props.nextBounds) {
      this.fitViewBounds(this.props.nextBounds);
      this.props.clearNextBounds();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let projects = Object.keys(nextProps.projects).map((key) => {
      return nextProps.projects[key];
    });

    let markers = projects.map((project, index) => {
      let iconUrl = "";
      let icon = {};
      let color = "#FF0000";

      if (project.legacy_url) {
        color = "#333333";
      }

      if (project.public) {
        iconUrl = "data:image/svg+xml;base64," + btoa(CreateMarkerSVG(color));
        icon = L.icon({
          iconUrl: iconUrl,
          iconSize: [16, 16],
          iconAnchor: [8, 8],
        });
      } else {
        iconUrl = "data:image/svg+xml;base64," + btoa(CreateLockSVG(color));
        icon = L.icon({
          iconUrl: iconUrl,
          iconSize: [16, 16],
          iconAnchor: [8, 8],
        });
      }

      let urlCompany = project.company_slug !== null ? "/c/" + project.company_slug : "/g/" + project.company_id;

      let urlProject = urlCompany + "/" + project.project_slug;

      let handleCompanyClick = (e) => {
        e.stopPropagation();
        let history = this.props.history;
        history.push(urlCompany);
      };

      let handleProjectClick = (e) => {
        if (typeof e.stopPropagation === "function") {
          e.stopPropagation();
        }
        let history = this.props.history;
        if (project.legacy_url) {
          window.location = project.legacy_url;
        } else {
          history.push(urlProject);
        }
      };
      return (
        <Marker key={index} icon={icon} position={[project.latitude, project.longitude]}>
          <Tooltip
            direction={project.flip_map_label ? "left" : "right"}
            permanent={true}
            className={project.flip_map_label ? "InventumMapTooltip reverseTooltip" : "InventumMapTooltip"}>
            <div className="InventumMapTooltipBackboard">
              <span>
                <span className="map-project-name" onClick={handleProjectClick}>
                  {project.project_name}
                </span>
              </span>
              <span className="map-company-name" onClick={handleCompanyClick}>
                {project.company_name}
              </span>
            </div>
          </Tooltip>
        </Marker>
      );
    });

    let availableLayers = nextProps.mapLayers.available;
    let loadedLayers = nextProps.sidebarItems;
    let layers = loadedLayers.map((layer, index) => {
      if (availableLayers.hasOwnProperty(layer.id)) {
        let opacity = layer.opacity;
        if (layer.visibility == "none") {
          return null;
        }
        let layerData = availableLayers[layer.id];
        return (
          <TileLayer
            zIndex={50 - index}
            key={layerData.id}
            url={layerData.url}
            minZoom={0}
            maxZoom={13}
            noWrap={true}
            tms={layerData.tms}
            opacity={opacity}
          />
        );
      }
      return null;
    });

    if (nextProps.tenementsLayerVisible !== this.props.tenementsLayerVisible) {
      if (!nextProps.tenementsLayerVisible) {
        this.setState({ selectedTenement: null });
      }
    }
    this.setState({ markers, layers });
  }

  handleMovement() {
    if (!this.map.current) {
      return;
    }
    localStorage.setItem("mapCenterLat", this.map.current.getCenter().lat);
    localStorage.setItem("mapCenterLng", this.map.current.getCenter().lng);
    localStorage.setItem("mapZoom", this.map.current.getZoom());
  }

  getTenementInfo(e) {
    if (!this.props.tenementsLayerVisible) return;

    let url = `https://api.inventum3d.com/geoserver/inventum-public/wms?
		SERVICE=WMS&
		VERSION=1.1.1&
		REQUEST=GetFeatureInfo&
		QUERY_LAYERS=inventum-public:au-wa-live-pending&
		LAYERS=inventum-public:au-wa-live-pending&
		INFO_FORMAT=application%2Fjson&
		FEATURE_COUNT=50&X=50&Y=50&SRS=EPSG%3A4283&
		WIDTH=101&
		HEIGHT=101&
		BBOX=${e.latlng.lng}%2C${e.latlng.lat}%2C${e.latlng.lng + 0.01}%2C${e.latlng.lat + 0.01}`;
    axios.get(url).then((response) => {
      this.setState({ selectedTenement: response.data.features });
    });
  }

  onMapCreated(map) {
    this.map.current = map;

    setTimeout(() => {
      // Weird hack to fix marker zoom bug.
      // Markers need to have their internal _map set to this leaflet map object
      // If missing when the markers are created, they will error and not animate properly
      // Fix is to set mapLoaded = true in a 200 millisecond timeout function to ensure the leaflet map object is available.
      // Then when the markers are loaded, the _map property *should* be set and avaiable
      this.setState({ mapLoaded: true });
    }, 200);

    let lat = parseFloat(localStorage.getItem("mapCenterLat"));
    let lng = parseFloat(localStorage.getItem("mapCenterLng"));
    let zoom = parseInt(localStorage.getItem("mapZoom"));

    if (!isNaN(lat) && !isNaN(lng) && !isNaN(zoom)) {
      map.setView({ lat, lng }, zoom);
    } else {
      map.setView(australiaCenter, australiaZoom);
    }

    map.on("moveend", this.handleMovement);
    map.on("zoomend", this.handleMovement);

    if (!isNaN(lat) && !isNaN(lng) && !isNaN(zoom)) {
      map.setView({ lat, lng }, zoom);
    }
  }

  componentDidMount() {
    // 2021 Bug. There is a weird race condition that occurs with the MapContainer component
    // Something causes it to not render. Possibly if the parent component hasn't finished rendering.
    // The bug occured every several reloads only on the deployed server version. It also occurs with the bare bones MapContainer
    // The solution is to add a small delay from when the component mounts before we mount the MapContainer
    // LeafletContainer Mounts -> 200 Millisecond delay -> MapContainer Component is added -> 200 Millisecond delay -> Markers are rendered

    setTimeout(() => {
      this.setState({ mapDelay: false });
    }, 200);
  }

  render() {
    let mapWorldStyle = (feature) => {
      return {
        fillOpacity: 1.0,
        fillColor: "#f2efe9",
        color: "#aaaaaa",
        weight: 1,
      };
    };
    let showDefaultVector = false;
    if (this.state.layers.length === 0 && this.state.offlineCoastline !== null) {
      showDefaultVector = true;
    }

    let showGeoJSONQueryString = false;
    if (Object.keys(this.props.geoJSON).length > 0) {
      showGeoJSONQueryString = true;
    }

    let showSelectedTenement = false;
    if (this.state.showSelectedTenement !== null) {
      showSelectedTenement = true;
    }

    let markersShouldCluster = true;
    if (this.props.path == "/c/:companySlug") {
      markersShouldCluster = false;
    }

    let styleFeature = (feature) => {
      if (feature.properties.hasOwnProperty("_INVENTUM_GROUP")) {
        return {
          color: feature.properties._INVENTUM_GROUP == "ADDED" ? "#FF0000" : "#00FF00",
        };
      }
    };

    // Slight map delay to prevent Leaflet Map Container from breaking. See notes in componentDidMount
    if (this.state.mapDelay) {
      return <div>Loading...</div>;
    }
    return (
      <RouterForwarder context={this.context}>
        <MapContainer
          center={mapCenter}
          zoom={zoomLevel}
          minZoom={2.5}
          maxZoom={13}
          whenCreated={this.onMapCreated}
          zoomControl={false}
          maxBounds={worldBounds}
          maxBoundsViscosity={1.0}
          zoomSnap={0.0}
          zoomDelta={0.1}
          attributionControl={false}
          onClick={this.getTenementInfo}>
          <ScaleControl imperial={false} />
          {showDefaultVector ? <GeoJSON style={mapWorldStyle} data={this.state.offlineCoastline} /> : null}
          {showGeoJSONQueryString ? <GeoJSON data={this.props.geoJSON} /> : null}
          {this.state.layers}
          {this.props.tenementsLayerVisible ? (
            <TileLayer
              zIndex={500}
              url={
                "https://api.inventum3d.com/geoserver/gwc/service/tms/1.0.0/inventum-public:au-wa-live-pending@EPSG%3A900913@png8/{z}/{x}/{y}.png8"
              }
              tms={true}
              minZoom={0}
              maxZoom={13}
              noWrap={true}
            />
          ) : null}
          {this.props.geojson.map((gj) => (
            <GeoJSON style={styleFeature} key={hash(gj)} onEachFeature={onEachFeature} data={gj} />
          ))}
          {this.state.selectedTenement !== null ? (
            <GeoJSON
              onEachFeature={(feature, layer) => {
                onEachFeature(feature, layer, true);
              }}
              key={hash(this.state.selectedTenement)}
              data={this.state.selectedTenement}
            />
          ) : null}
          {this.props.markersVisible && this.state.mapLoaded ? (
            <MarkersCluster markers={this.state.markers} cluster={markersShouldCluster} />
          ) : null}
          {this.props.tenementsLayerVisible ? <TenementsLegend /> : null}
        </MapContainer>
      </RouterForwarder>
    );
  }
}

function TenementsLegend() {
  let legendEntryStyle = {
    display: "flex",
  };
  return (
    <div className="tenementsLegend">
      Tenements Legend
      <div style={legendEntryStyle}>
        <div className="TenementLegendSwatch" style={{ backgroundColor: "#f46d43" }}></div>Exploration
      </div>
      <div style={legendEntryStyle}>
        <div className="TenementLegendSwatch" style={{ backgroundColor: "#fdae61" }}></div>Prospecting
      </div>
      <div style={legendEntryStyle}>
        <div className="TenementLegendSwatch" style={{ backgroundColor: "#fee08b" }}></div>Mining
      </div>
      <div style={legendEntryStyle}>
        <div className="TenementLegendSwatch" style={{ backgroundColor: "#ffffbf" }}></div>Misc
      </div>
      <div style={legendEntryStyle}>
        <div className="TenementLegendSwatch" style={{ backgroundColor: "#e6f598" }}></div>General Purpose
      </div>
      <div style={legendEntryStyle}>
        <div className="TenementLegendSwatch" style={{ backgroundColor: "#abdda4" }}></div>Mineral Claim
      </div>
      <div style={legendEntryStyle}>
        <div className="TenementLegendSwatch" style={{ backgroundColor: "#3288bd" }}></div>Other
      </div>
    </div>
  );
}

function onEachFeature(feature, layer, openPopup) {
  if (feature.properties && feature.properties.HOLDER1) {
    let tString = `
		<div class="tenementPopupInfo">
			<table>
				<thead>
					<tr>
						<th class="tenementPopupInfoTable">Holder</th>
						<th class="tenementPopupInfoTable">ID</th>
						<th class="tenementPopupInfoTable">Type</th>
						<th class="tenementPopupInfoTable">Survey Status</th>
						<th class="tenementPopupInfoTable">Tenement Status</th>
						<th class="tenementPopupInfoTable">End Date</th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td class="tenementPopupInfoTable">${feature.properties.ALL_HOLDER}</td>
						<td class="tenementPopupInfoTable">${feature.properties.FMT_TENID}</td>
						<td class="tenementPopupInfoTable">${feature.properties.TYPE}</td>
						<td class="tenementPopupInfoTable">${feature.properties.SURVSTATUS}</td>
						<td class="tenementPopupInfoTable">${feature.properties.TENSTATUS}</td>
						<td class="tenementPopupInfoTable">${feature.properties.ENDDATE}</td>
					</tr>
					</tbody>
				</table
			</div>
		`;
    layer.bindPopup(tString);

    //Only open popups automatically if they are from the selectedTenement (querying the 2D tiles)
    if (openPopup) {
      //HACK layer.openPopup won't work unless there is a small delay due to react not having bound the state yet
      setTimeout(() => {
        layer.openPopup();
      }, 100);
    }
  }
}


let mapStateToProps = (state) => ({
  geojson: state.mapData.geojson,
  markersVisible: state.mapData.markersVisible,
  tenementsLayerVisible: state.mapData.tenementsLayerVisible,
  nextBounds: state.mapData.nextBounds,
});

let mapDispatchToProps = (dispatch) => ({
  clearNextBounds: () => {
    dispatch(clearNextBounds());
  },
});

const LeafletContainerWrapper = withRouter(connect(mapStateToProps, mapDispatchToProps)(LeafletContainer));

export default LeafletContainerWrapper;
