import React, { useState, useEffect, useRef } from "react";

function ValueInput(props) {
  const [inputValue, setInputValue] = useState("");
  const [lastValidValue, setLastValidValue] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    // Sync state with props value
    if (props.value !== inputValue) {
      const newValue = props.value !== undefined ? String(Math.round(props.value)) : "";
      setInputValue(newValue);
      setLastValidValue(newValue);
    }
  }, [props.value]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;

    // Allow "-" or an empty string for user input
    if (newValue === "-" || newValue === "") {
      setInputValue(newValue);
      return;
    }

    const numberValue = Math.round(Number(newValue));

    // Update state only if the input is a valid number
    if (!isNaN(numberValue)) {
      setInputValue(newValue);
    }
  };

  const handleBlur = () => {
    // Revert to last valid value if input is empty or invalid
    if (inputValue === "" || inputValue === "-") {
      setInputValue(lastValidValue);
    } else {
      setLastValidValue(inputValue);
      if (typeof props.handleChange === "function") {
        props.handleChange(Number(inputValue));
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // Submit value on Enter
      if (inputValue !== "" && inputValue !== "-" && typeof props.handleChange === "function") {
        props.handleChange(Number(inputValue));
        setLastValidValue(inputValue);
      } else {
        setInputValue(lastValidValue);
      }

      // Remove focus on Enter
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  };

  return (
    <div style={{ display: "flex", fontSize: "14px", marginBottom: "10px" }}>
      <div className="PanelKeyCell" title={`${props.title}: ${props.tooltip}`}>
        {props.title}
      </div>
      <div className="PanelValueCell" title={`${props.title}: ${props.tooltip}`}>
        <input
          ref={inputRef}
          type="number"
          step={1}
          max={props.max}
          min={props.min}
          style={{ width: "100px" }}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  );
}

export default ValueInput;
